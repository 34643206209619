// src/pages/DashBoard.js
import React, { useEffect, useState } from "react";
import announcementIcon from "../assets/timetable.png";
import requestIcon from "../assets/NewRequest.jpg";
import fileUploadIcon from "../assets/register.png";
import { useNavigate } from "react-router-dom";
import { getPendingRequests } from "../api/request";
import { countPendingAccountRequests } from "../api/auth";
import { getLatestTable } from "../api/table";

const DashBoard = () => {
  const navigate = useNavigate();
  const [pendingRequestCount, setPendingRequestCount] = useState(0);
  const [pendingAccountRequestCount, setPendingAccountRequestCount] =
    useState(0);
  const [latestAnnouncement, setLatestAnnouncement] = useState(0);

  const isAuthenticated = () => {
    return !!localStorage.getItem("token");
  };

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate("/login");
    } else {
      // Fetch pending requests count
      fetchPendingRequests();

      // Fetch pending account requests count
      fetchPendingAccountRequests();

      // Fetch latest announcement
      fetchLatestAnnouncement();
    }
  }, [navigate]);

  const handleAnnouncementsClick = () => {
    navigate("/timeTable");
  };

  const handleRequestClick = () => {
    navigate("/request");
  };

  const handleFileUploadClick = () => {
    navigate("/newUserRequest");
  };

  const fetchPendingRequests = async () => {
    try {
      const response = await getPendingRequests();
      setPendingRequestCount(response.count); // Assuming you have state for pendingRequestCount
    } catch (error) {
      console.error("Error fetching pending requests:", error);
    }
  };

  const fetchPendingAccountRequests = async () => {
    try {
      const response = await countPendingAccountRequests();
      setPendingAccountRequestCount(response.count); // Update state with the count
    } catch (error) {
      console.error("Error fetching pending account requests:", error);
    }
  };

  const fetchLatestAnnouncement = async () => {
    try {
      const response = await getLatestTable();
      setLatestAnnouncement(response.createdAt); // Set the createdAt date from the response
    } catch (error) {
      console.error("Error fetching latest announcement:", error);
    }
  };

  const formattedDate = latestAnnouncement
    ? new Date(latestAnnouncement).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      })
    : //  +
      // " " +
      // new Date(latestAnnouncement).toLocaleTimeString("en-US", {
      //   hour: "2-digit",
      //   minute: "2-digit",
      //   second: "2-digit",
      // })
      "No update available";

  return (
    <div className="p-6 space-y-6 font-sans">
      {/* Data Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        <div className="bg-gray-100 p-6 rounded-lg shadow-md text-center">
          <h3 className="text-xl font-semibold">{formattedDate}</h3>
          <p className="text-gray-600">Last update for timetable</p>
        </div>
        <div className="bg-gray-100 p-6 rounded-lg shadow-md text-center">
          <h3 className="text-xl font-semibold">{pendingRequestCount}</h3>
          <p className="text-gray-600">Pending Requests</p>
        </div>
        <div className="bg-gray-100 p-6 rounded-lg shadow-md text-center">
          <h3 className="text-xl font-semibold">
            {pendingAccountRequestCount}
          </h3>
          <p className="text-gray-600">Pending New Users Requests</p>
        </div>
      </div>

      {/* Button Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div
          className="flex flex-col items-center bg-white p-6 rounded-lg shadow-md hover:shadow-lg cursor-pointer transition duration-300"
          onClick={handleAnnouncementsClick}
        >
          <img
            src={announcementIcon}
            alt="Announcements"
            className="w-16 h-16 mb-4"
          />
          <h4 className="text-lg font-semibold mb-2">Announcements</h4>
          <p className="text-gray-600 text-center">
            View the latest timetable announcements
          </p>
        </div>

        <div
          className="flex flex-col items-center bg-white p-6 rounded-lg shadow-md hover:shadow-lg cursor-pointer transition duration-300"
          onClick={handleRequestClick}
        >
          <img src={requestIcon} alt="Request" className="w-16 h-16 mb-4" />
          <h4 className="text-lg font-semibold mb-2">Request</h4>
          <p className="text-gray-600 text-center">Submit a new request</p>
        </div>

        <div
          className="flex flex-col items-center bg-white p-6 rounded-lg shadow-md hover:shadow-lg cursor-pointer transition duration-300"
          onClick={handleFileUploadClick}
        >
          <img
            src={fileUploadIcon}
            alt="File Upload"
            className="w-16 h-16 mb-4"
          />
          <h4 className="text-lg font-semibold mb-2">
            User Registration Request
          </h4>
          <p className="text-gray-600 text-center">
            Submit or reject a request
          </p>
        </div>
      </div>
    </div>
  );
};

export default DashBoard;
