// // src/pages/UserEdit.js
// import React, { useEffect, useState } from "react";
// import { useParams, useNavigate } from "react-router-dom";
// import { getUsers, adminUpdateUser } from "../api/user"; // Ensure these APIs are correctly implemented

// const UserEdit = () => {
//   const { id } = useParams(); // Get the user ID from the route
//   const navigate = useNavigate();

//   const [userDetails, setUserDetails] = useState({
//     fullName: "",
//     civilID: "",
//     email: "",
//     role: "",
//     phoneNumber: "",
//     fileNumber: "",
//   });

//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchUserDetails = async () => {
//       try {
//         setLoading(true);
//         const user = await getUsers(id); // Assuming `getUsers` accepts an ID
//         setUserDetails(user);
//       } catch (err) {
//         console.error("Error fetching user details:", err);
//         setError("Failed to fetch user details. Please try again later.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchUserDetails();
//   }, [id]);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setUserDetails((prev) => ({ ...prev, [name]: value }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       await adminUpdateUser(id, userDetails); // Assuming this API updates user details
//       navigate("/users"); // Navigate back to the user list after successful update
//     } catch (err) {
//       console.error("Error updating user:", err);
//       setError("Failed to update user. Please try again.");
//     }
//   };

//   if (loading) {
//     return <p>Loading...</p>;
//   }

//   if (error) {
//     return <p className="text-red-500">{error}</p>;
//   }

//   return (
//     <div className="max-w-4xl mx-auto p-4">
//       <h1 className="text-xl font-bold mb-4">Edit User</h1>
//       <form onSubmit={handleSubmit}>
//         <div className="mb-4">
//           <label className="block text-gray-700">Full Name</label>
//           <input
//             type="text"
//             name="fullName"
//             value={userDetails.fullName}
//             onChange={handleChange}
//             className="w-full p-2 border rounded"
//           />
//         </div>
//         <div className="mb-4">
//           <label className="block text-gray-700">Civil ID</label>
//           <input
//             type="text"
//             name="civilID"
//             value={userDetails.civilID}
//             onChange={handleChange}
//             className="w-full p-2 border rounded"
//           />
//         </div>
//         <div className="mb-4">
//           <label className="block text-gray-700">Email</label>
//           <input
//             type="email"
//             name="email"
//             value={userDetails.email}
//             onChange={handleChange}
//             className="w-full p-2 border rounded"
//           />
//         </div>
//         <div className="mb-4">
//           <label className="block text-gray-700">Role</label>
//           <input
//             type="text"
//             name="role"
//             value={userDetails.role}
//             onChange={handleChange}
//             className="w-full p-2 border rounded"
//           />
//         </div>
//         <div className="mb-4">
//           <label className="block text-gray-700">Phone Number</label>
//           <input
//             type="text"
//             name="phoneNumber"
//             value={userDetails.phoneNumber}
//             onChange={handleChange}
//             className="w-full p-2 border rounded"
//           />
//         </div>
//         <div className="mb-4">
//           <label className="block text-gray-700">File Number</label>
//           <input
//             type="text"
//             name="fileNumber"
//             value={userDetails.fileNumber}
//             onChange={handleChange}
//             className="w-full p-2 border rounded"
//           />
//         </div>
//         <button
//           type="submit"
//           className="px-4 py-2 bg-sky-600 text-white rounded hover:bg-sky-500"
//         >
//           Save Changes
//         </button>
//       </form>
//     </div>
//   );
// };

// export default UserEdit;

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getUserById, adminUpdateUser } from "../api/user"; // Ensure these APIs are correctly implemented

const UserEdit = () => {
  const { id } = useParams(); // Get the user ID from the route
  const navigate = useNavigate();

  const [userDetails, setUserDetails] = useState({
    fullName: "",
    civilID: "",
    email: "",
    role: "",
    phoneNumber: "",
    fileNumber: "",
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        setLoading(true);
        const user = await getUserById(id); // Assuming `getUserById` fetches a single user by ID
        setUserDetails(user);
      } catch (err) {
        console.error("Error fetching user details:", err);
        setError("Failed to fetch user details. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to save these changes?"
    );

    if (!confirmed) return;

    try {
      await adminUpdateUser(id, userDetails); // Assuming this API updates user details
      alert("User details updated successfully!");
      navigate("/employees"); // Navigate back to the user list after successful update
    } catch (err) {
      console.error("Error updating user:", err);
      setError("Failed to update user. Please try again.");
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p className="text-red-500">{error}</p>;
  }

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h1 className="text-xl font-bold mb-4">Edit User</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700">Full Name</label>
          <input
            type="text"
            name="fullName"
            value={userDetails.fullName}
            onChange={handleChange}
            className="w-full p-2 border rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Civil ID</label>
          <input
            type="text"
            name="civilID"
            value={userDetails.civilID}
            onChange={handleChange}
            className="w-full p-2 border rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Email</label>
          <input
            type="email"
            name="email"
            value={userDetails.email}
            onChange={handleChange}
            className="w-full p-2 border rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Role</label>
          <input
            type="text"
            name="role"
            value={userDetails.role}
            onChange={handleChange}
            className="w-full p-2 border rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Phone Number</label>
          <input
            type="text"
            name="phoneNumber"
            value={userDetails.phoneNumber}
            onChange={handleChange}
            className="w-full p-2 border rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">File Number</label>
          <input
            type="text"
            name="fileNumber"
            value={userDetails.fileNumber}
            onChange={handleChange}
            className="w-full p-2 border rounded"
          />
        </div>
        <button
          type="submit"
          className="px-4 py-2 bg-sky-600 text-white rounded hover:bg-sky-500"
        >
          Save Changes
        </button>
      </form>
    </div>
  );
};

export default UserEdit;
