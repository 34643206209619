//src/api/request
import instance from ".";

// Get all requests
export const getRequests = async () => {
  const { data } = await instance.get("/api/requests/getrequests");
  return data;
};

// get request by id
export const getRequestById = async (id) => {
  const { data } = await instance.get(`/api/requests/getrequest/${id}`);
  return data;
};

// update request
export const updateRequest = async (request) => {
  const { data } = await instance.put("/api/requests/updatedRequest", request);
  return data;
};

// Fetch pending requests
export const getPendingRequests = async () => {
  const { data } = await instance.get("/api/requests/pendingRequests");
  return data;
};
